// eslint-disable-next-line no-undef
const URL = process.env.REACT_APP_URL_BACK_API
const API_URL = `${URL}/api`
// eslint-disable-next-line no-undef
const PAGE_URL = process.env.REACT_APP_URL_FRONT
// eslint-disable-next-line no-undef
const IS_PRODUCTION = process.env.REACT_APP_IS_PRODUCTION


const REJECT_OPTIONS = [
  'Your video did not contain the competition video frames.',
  'Your video has not been uploaded to a supported social media platform (Instagram, TikTok, YouTube).',
  'Your video is more than 2 minutes long.',
  'Your video did not comply with the terms & conditions of the platform.'
]

export {
  URL,
  API_URL,
  PAGE_URL,
  REJECT_OPTIONS,
  IS_PRODUCTION
}
