import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { Container, Form, InputGroup, Modal, Row, Spinner } from 'react-bootstrap'
import { useContext, useEffect, useMemo, useState } from 'react'
import { AppContext } from '../context'
import { getVideos, get_selectedWinner, post_selectedWinner } from '../api/videos'
import { useInfiniteQuery, useQuery } from 'react-query'
import AdminWinnerVideosCard from './AdminWinnerVideosCard'
import Button from 'react-bootstrap/Button'
import TextInputEditor from '../components/TextInputEditor'
import { useParams } from 'react-router-dom'

const ChoiceWinners = () => {
  const { token, user } = useContext(AppContext)
  const [searchWord, setSearchWord] = useState('')
  const [selected, setSelected] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [adminDescription, setAdminDescription] = useState('')
  const [saveState, setSaveState] = useState('')
  const { competition_id } = useParams()

  const { data, isFetching, hasNextPage, fetchNextPage, refetch } =
    useInfiniteQuery(
      ['winner_videos', competition_id, token, searchWord],
      ({ pageParam = 1 }) =>
        getVideos({
          page: pageParam,
          status: 'Approved',
          search: searchWord,
          competition_id,
        }, token),
      {
        getNextPageParam: (lastPage) =>
          Number(lastPage.actual_page) < lastPage.total_pages
            ? Number(lastPage.actual_page) + 1
            : false,
        initialData: { pages: [{ data: [] }], pageParams: 1 },
        enabled: token !== '' && (user?.user_serializer.is_superuser ?? false),
      }
    )
  const videos = useMemo(
    () =>
      data.pages.reduce((prevVideos, page) => prevVideos.concat(page.data), []),
    [data]
  )
  const { data: selectedWinnerData,  } = useQuery(
    ['getSelectedWinner', competition_id, token],
    () => get_selectedWinner({ competition_id }, token),
    {
      initialData: {
        data: {
          admin_description: '',
          description: '',
          hosted_in: '',
          id: null,
          is_banned_from_leaderboard: false,
          is_staff_pick: false,
          last_edited_by: 0,
          name: '',
          reason_rejected: '',
          recommended: false,
          status: '',
          thumbnail: '',
          upload_time: '',
          user_age: '',
          user_city: '',
          user_country: '',
          user_email: '',
          user_last_name: '',
          user_name: '',
          user_phone: '',
          video_link: '',
        },
        ok: false,
      },
      enabled: token !== '' && (user?.user_serializer.is_superuser ?? false) && Boolean(competition_id),
      refetchOnWindowFocus: false
    }
  )

  const onChangeSearch = (query) => {
    setSearchWord(query)
  }

  useEffect(() => {
    refetch()
  }, [searchWord])

  const handleSelect = (video) => {
    setSelected(video)
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handlePostMainPrize = async (id) => {
    const body = {
      winner_id: id,
      description: adminDescription,
      competition_id,
    }
    const { ok } = await post_selectedWinner(body, token)
    if (!ok) {
      setSaveState('error')
    } else {
      setSaveState('success')
      setTimeout(() => {
        window.location.reload()
      }, 800)
    }
  }

  useEffect(() => {
    if (!saveState) return

    const stateTimeout =
      setTimeout(() => {
        setSaveState('')
      }, 5000)

    return () => clearTimeout(stateTimeout)

  }, [saveState])

  return (
    <>
      <Container fluid className='choice-winners my-5'>
        <InputGroup className="mb-3">
          <InputGroup.Text className="bg-white border-0 ms-n5" id="basic-addon1">
            <FontAwesomeIcon icon={faMagnifyingGlass} size={'lg'} />
          </InputGroup.Text>
          <Form.Control
            aria-label="search"
            value={searchWord}
            onChange={(e) => onChangeSearch(e.target.value)}
            aria-describedby="basic-addon1"
            style={{ backgroundColor: '#EEEEEE', border: 'none' }}
          />
        </InputGroup>
        <div className="my-4">
          <h5>Main Prize winner:</h5>
        </div>
        <div>
          {videos.length !== 0 && (
            <Row className="mb-4">
              {videos.map((video) =>
                <AdminWinnerVideosCard
                  video={video}
                  key={video.id}
                  voted={video.already_voted}
                  description={video.description}
                  socialNet={video.hosted_in}
                  id={video.id}
                  bannedLeaderboard={video.is_banned_from_leaderboard}
                  likes={video.votes}
                  name={video.name}
                  videoName={video.name}
                  videoSrc={video.video_link}
                  firstName={video.user_name}
                  lastName={video.user_last_name}
                  staffPick={video.is_staff_pick}
                  recommended={video.recommended}
                  image={video.thumbnail}
                  email={video.user_email}
                  age={video.user_age}
                  mobile={video.user_phone}
                  city={video.user_city}
                  country={video.user_country}
                  handleSelect={() => handleSelect(video)}
                  selected={selected?.id}
                  showModal={showModal}
                  selectedWinnerId={selectedWinnerData?.data.id}
                />
              )}
            </Row>
          )}
          {token === '' || isFetching ? (
            <div className="text-center" style={{ marginBottom: '49.25px' }}>
              <Spinner animation="border" variant="warning" />
            </div>
          ) : hasNextPage ? (
            <div className="text-center mb-5" onClick={() => fetchNextPage()}>
              <Button variant="rs-third">Load more videos</Button>
            </div>
          ) : videos.length === 0 ? (
            <div>No videos to show</div>
          ) : (
            <div style={{ height: '86px' }} />
          )}
        </div>
        <Modal
          show={showModal && selected !== null}
          onHide={handleCloseModal}
          centered
          dialogClassName='modal-xl'
          className="p-0"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">Save this video as the main prize winner?</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseModal}></button>
            </div>
            <div className="modal-body">
              <p style={{ color: 'black', fontSize: 14 }}><b>{selected?.name}</b></p>
              <p style={{ color: 'black', fontSize: 14 }}>{selected?.user_name} {selected?.user_last_name}</p>
              <p style={{ color: 'black', fontSize: 14 }}><b>{selected?.user_email}</b></p>
              <TextInputEditor
                value={adminDescription}
                setValue={setAdminDescription}
              />
            </div>
            {saveState !== '' &&
              <p className='m-3'>{saveState === 'success' ? 'Changes saved successfully' : saveState === 'error' ? 'You must provide a description' : 'Something went wrong'}</p>
            }
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={handleCloseModal}>Close</button>
              <button type="button" className="btn btn-primary" onClick={() => handlePostMainPrize(selected?.id)}>Save changes</button>
            </div>
          </div>
        </Modal>
      </Container>
    </>
  )
}

export default ChoiceWinners
