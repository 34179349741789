import { Container, Spinner } from 'react-bootstrap'
// import prizeIcon from '../../assets/landing/first-price-icon.png'
// import luckyIcon from '../../assets/icons/clover-solid.svg'
import Form from 'react-bootstrap/Form'
import { Link, NavLink, useParams } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../context'
import { useQuery } from 'react-query'
import { getLeaderboardByAdmin, get_luckyWinner, get_selectedWinner, post_luckyWinner, post_selectedWinner } from '../../api/videos'
import AdminWinnerVideos from '../../components/AdminWinnerVideos'
import TextInputEditor from '../../components/TextInputEditor'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { getCompetitions } from '../../api/competitions'
import { useMemo } from 'react'

const AdminWinners = () => {
  const { token, user } = useContext(AppContext)
  const [adminDescriptionEdit, setAdminDescriptionEdit] = useState('')
  const [saveState, setSaveState] = useState('')
  const { competition_id } = useParams()

  const { data: competitionsData } = useQuery(
    ['getCompetitions', token],
    () => getCompetitions(token),
    {
      initialData: {
        data: {
          competitions: [],
          current_page: 1,
          total_pages: 1,
        },
        ok: false,
      },
      enabled: token !== '' && (user?.user_serializer.is_superuser ?? false),
      refetchOnWindowFocus: false
    }
  )
  const competitionSelected = useMemo(() => {
    if (!competitionsData.ok) return undefined
    return competitionsData.data.competitions.find((competition) =>
      competition.id === Number(competition_id)
    )

  }, [competitionsData, competition_id])
  const { isFetching, data: leaderboardData } = useQuery(
    ['getLeaderboardByAdmin', competition_id, token],
    () => getLeaderboardByAdmin({ competition_id }, token),
    {
      initialData: { leaderboardData: [] },
      refetchOnWindowFocus: false, 
      enabled: token !== '' && (user?.user_serializer.is_superuser ?? false) && Boolean(competition_id) && Boolean(competitionSelected),
    }
  )

  const { isFetching: loadingSW, data: selectedWinnerData } = useQuery(
    ['getSelectedWinner', competition_id, token],
    () => get_selectedWinner({ competition_id }, token),
    {
      initialData: {
        data: {
          admin_description: '',
          description: '',
          hosted_in: '',
          id: null,
          is_banned_from_leaderboard: false,
          is_staff_pick: false,
          last_edited_by: 0,
          name: '',
          reason_rejected: '',
          recommended: false,
          status: '',
          thumbnail: '',
          upload_time: '',
          user_age: '',
          user_city: '',
          user_country: '',
          user_email: '',
          user_last_name: '',
          user_name: '',
          user_phone: '',
          video_link: '',
        },
        ok: false,
      },
      enabled: token !== '' && (user?.user_serializer.is_superuser ?? false) && Boolean(competition_id),
      refetchOnWindowFocus: false
    }
  )

  const { data: luckyDrawWinnerData } = useQuery(
    ['getLuckyWinnerName', competition_id, token],
    () => get_luckyWinner({ competition_id }, token),
    {
      initialData: {
        data: {
          winner_name: '',
        },
        ok: false,
      },
      enabled: token !== '' && (user?.user_serializer.is_superuser ?? false) && Boolean(competition_id),
      refetchOnWindowFocus: false
    }
  )

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required('Competition name is required')
    }),
    onSubmit: async ({ name }) => {
      const body = {
        winner_name: name,
        competition_id: Number(competition_id),
      }
      const { ok } = await post_luckyWinner(body, token)
      if (!ok) {
        setSaveState('error')
      } else {
        setSaveState('success')
      }
    }
  })

  useEffect(() => {
    if (!selectedWinnerData.ok) return
    setAdminDescriptionEdit(selectedWinnerData.data.admin_description)
  }, [selectedWinnerData])

  const handleDiscardChanges = () => {
    if (!selectedWinnerData.ok) return

    setAdminDescriptionEdit(selectedWinnerData.data.admin_description)
  }

  const handlePostEditedMainPrize = async (id) => {
    const body = {
      winner_id: id,
      description: adminDescriptionEdit,
      competition_id,
    }
    const { ok } = await post_selectedWinner(body, token)
    if (!ok) {
      setSaveState('error')
    } else {
      setSaveState('success')
    }
  }

  useEffect(() => {
    if (!saveState) return

    const stateTimeout =
      setTimeout(() => {
        setSaveState('')
      }, 5000)

    return () => clearTimeout(stateTimeout)

  }, [saveState])

  useEffect(() => {

    if (luckyDrawWinnerData.data.error) {
      formik.setFieldValue('name', '')
    } else {
      formik.setFieldValue('name', luckyDrawWinnerData.data.winner_name)
    }
  }, [luckyDrawWinnerData.data])

  const handleDiscardChangesLucky = () => {
    formik.setFieldValue('name', luckyDrawWinnerData.data.winner_name)
  }

  return (
    <>
      <Container fluid>
        {competitionSelected ? (
          <>
            <div className='d-flex justify-content-end'>
              <Link className='btn-rs-white-yellow btn' to={`/admin-winners/edit/${competition_id}`}>
                Edit competition
              </Link>
            </div>

            {competitionSelected && (
              <section className='mainPrize my-4 border py-2 px-3' style={{ minHeight: '15rem', width: '100%' }}>
                <div className='my-5 mx-4 d-flex justify-content-between'>
                  <div className='d-flex gap-2 '>
                    <h3>{competitionSelected.selected_winner_title}</h3>
                    {/* <img src={prizeIcon} alt='prize' style={{ width: '2rem' }} /> */}
                  </div>
                  <div>
                    <NavLink activeclassname="active" to={`/people-choice-winners/${competition_id}`}>
                      <button type="button" className="btn btn-outline-secondary">{selectedWinnerData.ok && !selectedWinnerData.data.error ? 'Change winner' : 'Add winner +'}</button>
                    </NavLink>
                  </div>
                </div>
                <div className='my-4'>
                  {selectedWinnerData && !selectedWinnerData.data.error ? (
                    <>
                      <AdminWinnerVideos
                        key={selectedWinnerData.data.id}
                        video={selectedWinnerData.data}
                        position={1}
                        image={selectedWinnerData.data.thumbnail}
                      />
                      <TextInputEditor
                        value={adminDescriptionEdit}
                        setValue={setAdminDescriptionEdit}
                      />
                      {saveState !== '' &&
                        <p className='m-3'>{saveState === 'success' ? 'Changes saved successfully' : 'Something went wrong'}</p>
                      }
                      <div className='d-flex justify-content-end mt-5 mb-4 mr-3 gap-5'>
                        <button type="button" className="btn btn-outline-secondary" style={{height: '42px'}} onClick={handleDiscardChanges}>Discard changes</button>
                        <button type="button" className="btn btn-primary" onClick={() => handlePostEditedMainPrize(selectedWinnerData.data.id)}>Save changes</button>
                      </div>
                    </>
                  ) : loadingSW ? (
                    <div className="text-center">
                      <Spinner animation="border" variant="warning" />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </section>
            )}
            {competitionSelected && (
              <section className='people_choiseWinner my-4 border py-2 px-3' style={{ minHeight: '15rem' }}>
                <div className='my-5 mx-4 d-flex gap-2 justify-content-between'>
                  <h3>{competitionSelected.leaderboard_winners_title}</h3>
                </div>
                {leaderboardData.ok ? (
                  leaderboardData.data.map((element, index) => (
                    <AdminWinnerVideos
                      key={element.id}
                      position={index + 1}
                      video={element}
                      image={element.thumbnail}
                    />
                  ))
                ) : isFetching ? (
                  <div className="text-center">
                    <Spinner animation="border" variant="warning" />
                  </div>
                ) : (
                  <></>
                )}
              </section>
            )}
            {competitionSelected && (
              <section className='people_choiseWinner my-4 border py-2 px-3'>
                <div className='mt-5 mb-4 mx-4 d-flex justify-content-between'>
                  <div className='d-flex gap-2'>
                    <h3>{competitionSelected.lucky_winner_title}</h3>
                    {/* <img src={luckyIcon} alt='prize' style={{ width: '2rem' }} /> */}
                  </div>
                </div>
                {luckyDrawWinnerData && luckyDrawWinnerData.ok && (
                  <div className='mx-4'>
                    <Form className="w-100 my-5" onSubmit={formik.handleSubmit}>
                      <Form.Group
                        className="position-relative mb-4 fw-bold"
                        controlId="video_link"
                      >
                        <Form.Label>Competitor’s name</Form.Label>
                        <Form.Control
                          placeholder="Enter competitors name"
                          name="name"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          isInvalid={formik.errors.name}
                        />
                        <Form.Control.Feedback
                          className="position-absolute mt-0 text-5"
                          type="invalid"
                        >
                          {formik.errors.name}
                        </Form.Control.Feedback>
                      </Form.Group>
                      {saveState !== '' &&
                        <p className='m-3'>{saveState === 'success' ? 'Changes saved successfully' : 'Something went wrong'}</p>
                      }
                      <div className='d-flex justify-content-end mt-2 mb-4 mr-3 gap-5'>
                        <button type="button" className="btn btn-outline-secondary" onClick={handleDiscardChangesLucky}>Discard changes</button>
                        <button type="submit" className="btn btn-primary " >Save changes</button>
                      </div>
                    </Form>
                  </div >
                )}
              </section >
            )}
          </>
        ) : (
          <div>
            <h3>This competition doesn&apos;t exist</h3>
          </div>
        )}
      </Container >
    </>
  )
}

export default AdminWinners