import { Card, Col } from 'react-bootstrap'
import playIcon from '../assets/landing/playIcon.png'
import PropTypes from 'prop-types'
import * as constants from '../constants'
import { AppContext } from '../context'
import { useContext, useEffect, useState } from 'react'
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const AdminWinnerVideosCard = (props) => {
  const { openVideoModal } = useContext(AppContext)
  

  return (
    <>
      <Col key={props.id} xl={4} md={4} sm={12} className='mb-4'>
        <Card className={`video-card ${props.selected === props.id ? 'selected' : ''} ${props.selectedWinnerId === props.id ? 'selectedW' : ''}   crop h-100`}>
          <Card.Img src={`${props.image.indexOf('http://') === 0 || props.image.indexOf('https://') === 0 ? '' : constants.URL}${props.image}`} alt="Card image" style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }} />
          <Card.ImgOverlay className="text-white play-icon-winners ">
            <img src={playIcon} className="play-icon cursor-pointer" alt="" onClick={() => openVideoModal({ ...props.video, isAdmin: true })} />
          </Card.ImgOverlay>
          <div className='d-flex flex-column' style={{ backgroundColor: 'rgba( 0, 0, 0, 0.8)', width: '100%' }}>
            <div className='p-2'>
              <p style={{ color: 'white', fontSize: 14 }}><b>{props.videoName}</b></p>
              <p style={{ color: 'white', fontSize: 14 }}>{props.firstName} {props.lastName}</p>
              <p style={{ color: 'white', fontSize: 14 }}><b>{props.email}</b></p>
            </div>
            <button
              type='button'
              className="select-winner p-0"
              onClick={props.handleSelect}
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              {props.selectedWinnerId && (props.id === props.selectedWinnerId) ? (
                <p className="text-white mb-0">
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    size="lg"
                    className="me-2"
                  />
                  Selected
                </p>
              ) : (

                <p className="text-white mb-0">
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    size="lg"
                    className="me-2"
                  />
                  Select
                </p>
              )}
            </button>
          </div>
        </Card>
      </Col>
    </>
  )
}

export default AdminWinnerVideosCard

AdminWinnerVideosCard.propTypes = {
  showModal: PropTypes.bool.isRequired,
  selected: PropTypes.number,
  handleSelect: PropTypes.func,
  video: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  socialNet: PropTypes.string.isRequired,
  likes: PropTypes.number.isRequired,
  videoName: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string,
  country: PropTypes.string,
  city: PropTypes.string,
  age: PropTypes.string,
  mobile: PropTypes.string,
  image: PropTypes.string,
  staffPick: PropTypes.bool,
  recommended: PropTypes.bool,
  description: PropTypes.string.isRequired,
  videoSrc: PropTypes.string.isRequired,
  voted: PropTypes.bool,
  show: PropTypes.bool,
  pendingVideo: PropTypes.func,
  rejectVideoModal: PropTypes.func,
  staffPickVideo: PropTypes.func,
  removeRecommended: PropTypes.func,
  isAdmin: PropTypes.bool,
  bannedLeaderboard: PropTypes.bool,
  bannedLeaderboardVideo: PropTypes.func,
  selectedWinnerId: PropTypes.number,
}
