import { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import { unsubscribeEmail } from '../../api/users'

const UnsubcribeEmail = () => {
  const [menssage, setMenssage] = useState('')
  const { email } = useParams()

  useEffect(() => {
    const doCall = async () => {
      const response = await unsubscribeEmail(email)

      if (response.error) setMenssage('Opss, Something went wrong.')
      else if (response)
        setMenssage(
          'You have successfully unsubscribed from our newsletter. We\'re sorry to see you go and hope you\'ll consider rejoining us in the future. Thank you for your time with us.'
        )
    }
    doCall()
  }, [email])

  return (
    <>
      <Helmet>
        <title>Unsubcribe | Outback Explorer Competition</title>
      </Helmet>
      <div className="AuthContainer d-flex justify-content-center align-items-center h-100vh">
        <div className="AuthBox__RegisterToken bg-white p-5">
          {menssage ? (
            <p className="text-2 fw-semibold text-center">{menssage}</p>
          ) : (
            <Spinner animation="border" variant="warning" />
          )}
        </div>
      </div>
    </>
  )
}

export default UnsubcribeEmail
