import { useContext, useMemo } from 'react'
import { Button, Row, Spinner } from 'react-bootstrap'
import { useInfiniteQuery } from 'react-query'
import { getVideos, patchEditVideo } from '../../api/videos'
import { AppContext } from '../../context'
import AdminRecommendedVideosCard from '../../components/AdminRecommendedVideosCard'

const AdminRecommendedVideos = () => {
  const { token } = useContext(AppContext)
  const { data, isFetching, hasNextPage, fetchNextPage, refetch } =
    useInfiniteQuery(
      ['recommended', token],
      ({ pageParam = 1 }) =>
        getVideos({ 
          page: pageParam, 
          recommended: 'true',
          all: 'true', 
          per_page: 12, 
        }, token),
      {
        getNextPageParam: (lastPage) =>
          lastPage.actual_page < lastPage.total_pages
            ? Number(lastPage.actual_page) + 1
            : false,
        initialData: { pages: [{ data: [] }], pageParams: 1 },
        enabled: token !== '',
      }
    )
  const videos = useMemo(
    () =>
      data.pages.reduce((prevVideos, page) => prevVideos.concat(page.data), []),
    [data]
  )

  const removeRecommended = async (videoid, recommended) => {
    const body = {
      recommended,
    }

    const response = await patchEditVideo(token, videoid , body)

    if(response.error) console.error(response.error)
    else if (response) {
      refetch()
    }
  }

  return (
    <>
      {token === '' || isFetching ? (
        <div className="text-center" style={{ marginBottom: '49.25px' }}>
          <Spinner animation="border" variant="warning" />
        </div>
      ) : videos.length === 0 ? (
        <div>No videos in staff pick.</div>
      ) : (
        <Row className="mb-4">
          {videos.length !== 0 ? videos.map((video) =>
            <AdminRecommendedVideosCard
              key={video.id}
              removeRecommended={removeRecommended}
              video={video}
            />
          ): <></>}
        </Row>
      )}
      {hasNextPage? 
        <div className="text-center mb-5" onClick={() => fetchNextPage()}>
          <Button variant="rs-third">Load more videos</Button>
        </div>
        : null
      } 
    </>
  )
}

export default AdminRecommendedVideos