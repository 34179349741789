import ReactDOM from 'react-dom/client'
import AppContextProvider from './context'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import App from './router/App'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/thumbs'
import 'swiper/css/effect-fade'
import 'swiper/css/free-mode'

import './styles/index.sass'

const root = ReactDOM.createRoot(document.getElementById('root'))

const queryClient = new QueryClient({
  // defaultOptions: {
  //   queries: {
  //     refetchOnWindowFocus: false,
  //   },
  // },
})

root.render(
  <QueryClientProvider client={queryClient}>
    <AppContextProvider>
      <App/>
    </AppContextProvider>
    <ReactQueryDevtools />
  </QueryClientProvider>
)
