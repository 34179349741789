import { useCountdown } from '../hooks/useCountdown'
import PropTypes from 'prop-types'

const CountdownTimer = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate)

  if (days + hours + minutes + seconds > 0) {
    return (
      <div className="show-counter">
        <div className="counter">
          <p className="counter-value font_roboto_slab">{days}</p>
          <span className="counter-unity">Days</span>
        </div>
        <div className="counter">
          <p className="counter-value font_roboto_slab">{hours}</p>
          <span className="counter-unity">Hours</span>
        </div>
        <div className="counter">
          <p className="counter-value font_roboto_slab">{minutes}</p>
          <span className="counter-unity">Mins</span>
        </div>
        <div className="counter">
          <p className="counter-value font_roboto_slab">{seconds}</p>
          <span className="counter-unity">Seconds</span>
        </div>
      </div>
    )
  }
}

export default CountdownTimer

CountdownTimer.propTypes = {
  targetDate: PropTypes.string
}