import { getQuery, patchQuery } from './apiFunctions'

export const getAdminSetting = async (token) => {
  return await getQuery({
    path: '/api/admin-settings/',
    token,
    callback: (response) => response.data,
  })
}
export const patchAdminSetting = async (token, body) => {
  return await patchQuery({
    path: '/api/admin-settings/',
    token,
    body,
    callback: (response) => response.data,
  })
}


