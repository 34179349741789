import { getQuery, patchQuery, postQuery, deleteQuery } from './apiFunctions'


export const getJudges = async (token) => {
  return await getQuery({
    path: '/api/judges/',
    token,
  })
}

export const addJudge = async (token, body) => {
  return await postQuery({
    path: '/api/judges/',
    token,
    body
  })
}

export const modifyJudge = async (token, judgeId, body) => {
  return await patchQuery({
    path: `/api/judges/${judgeId}/`,
    token,
    body
  })
}

export const deleteJudge = async (token, judgeId) => {
  return await deleteQuery({
    path: `/api/judges/${judgeId}/`,
    token
  })
}