import { useContext } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Modal, Button } from 'react-bootstrap'
import instagramIcon from '../assets/icons/instagram-icon.svg'
import tiktokIcon from '../assets/icons/tiktok-icon.svg'
import youtubeIcon from '../assets/icons/youtube-icon.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DownloadModalContext } from '../pages/Home'
import { AppContext } from '../context'

export default function DownloadModal() {
  const { show, setShow } = useContext(DownloadModalContext)
  const { competitionInfo } = useContext(AppContext)
  const handleClose = () => setShow(false)

  const handleDownload = (videoPath) => {
    const link = document.createElement('a')
    link.href = videoPath
    link.download = videoPath.split('/').pop()
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  return (
    <>
      <Modal show={show} onHide={handleClose} centered size="lg" className="custom-modal">
        <Modal.Header closeButton style={{ borderBottom: 'none' }}>
          <Modal.Title className="w-100 text-center fw-semibold font_open_sans">Download video frames</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ color: '#424242', marginBottom: '32px' }} className='font_open_sans'>
            Your video must include the <strong>Outback Explorer Competition</strong> video frames.
          </p>
          <span style={{ fontWeight: 'bold' }} className="text-5 font_open_sans">
            Select your platform:
          </span>
          <div className="d-flex flex-wrap gap-3 mt-1 font_open_sans" style={{marginBottom: '32px'}}>
            {competitionInfo && competitionInfo.state !== 'finished' && (
              <>
                <Button
                  variant=""
                  onClick={() => handleDownload('/videos/Outback_Frames_Tiktok-Instagram.mp4')}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: '1 0 0',
                    gap: '8px',
                    backgroundColor: 'black',
                    borderWidth: 0,
                    color: 'white',
                    fontWeight: 'bold',
                    borderRadius: '10px',
                    height: '36px'
                  }}
                >
                  <img src={tiktokIcon} alt="tiktok icon" style={{ width: 24 }} />
                  <span style={{ marginRight: 10 }}>TikTok</span>
                </Button>
                <Button
                  variant=""
                  onClick={() => handleDownload('/videos/Outback_Explorer_Frames_-_Youtube.mp4')}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: '1 0 0',
                    gap: '8px',
                    backgroundColor: '#FF0E00',
                    borderWidth: 0,
                    color: 'white',
                    fontWeight: 'bold',
                    borderRadius: '10px',
                    height: '36px'
                  }}
                >
                  <img src={youtubeIcon} alt="youtube icon" style={{ width: 24 }} />
                  <span style={{ marginRight: 10, marginTop: '-0.55px' }}>YouTube</span>
                </Button>
                <Button
                  variant=""
                  onClick={() => handleDownload('/videos/Outback_Frames_Tiktok-Instagram.mp4')}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: '1 0 0',
                    gap: '8px',
                    background: 'linear-gradient(84deg, #FF007E -39.09%, #FF5900 154.66%)',
                    borderWidth: 0,
                    color: 'white',
                    fontWeight: 'bold',
                    borderRadius: '10px',
                    height: '36px'
                  }}
                >
                  <img src={instagramIcon} alt="instagram icon" style={{ width: 24 }} />
                  <span style={{ marginRight: 10, marginTop: '-1px' }}>Instagram</span>
                </Button>
              </>
            )}
          </div>
          <div className='d-flex flex-column gap-1 font_open_sans'>
            <span className='text-5' style={{color: '#424242'}}>If <span className='fw-bold' style={{color: 'black'}}>downloading on iPhone</span>, your device may save the video frame to your “Files” app.</span>
            <span className='text-5' style={{color: '#424242'}}>To add the video frame to your camera roll/photos app:</span>
            <ul className='text-5' style={{color: '#424242'}}>
              <li>Locate the file in your “Files” app.</li>
              <li>Click the “Share” button in bottom left of the screen.</li>
              <li>Click “Save Video”.</li>
              <li>Open your “Photos” app and select “Albums” then “Recent”.</li>
              <li>The video frame will then be visible in your Camera Roll/Photos app.</li>
            </ul>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

DownloadModal.propTypes = {}
