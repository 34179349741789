import { getQuery, postQuery } from './apiFunctions'

export const userMe = async (token) => {
  return await getQuery({
    path: '/api/users/me/',
    token,
    callback: (response) => response.data,
  })
}

export const login = async (body) => {
  return await postQuery({
    path: '/login/',
    body,
    callback: (response) => response.data,
  })
}

export const register = async (body) => {
  return await postQuery({
    path: '/signup/',
    body,
  })
}

export const confirmEmail = async (token) => {
  return await postQuery({
    path: `/signup/account-confirm-email/${token}/`,
    body: {
      key: token,
    },
    callback: (response) => response.data,
  })
}

export const unsubscribeEmail = async (email) => {
  return await postQuery({
    path: '/api/unsubscribe-email/',
    body: {
      email,
    },
    callback: (response) => response.data,
  })
}

export const resendEmailConfirmation = async (body) => {
  return await postQuery({
    path: '/resend-email/',
    body,
    callback: (response) => response.data,
  })
}

export const passwordRecovery = async (body) => {
  return await postQuery({
    path: '/api/users/password-recovery/',
    body,
    callback: (response) => response.data,
  })
}

export const checkPasswordRecoveryToken = async (body) => {
  return await postQuery({
    path: '/api/users/password-recovery/check-token/',
    body,
    callback: (response) => response.data,
  })
}

export const passwordRecoveryConfirm = async (body) => {
  return await postQuery({
    path: '/api/users/password-recovery/confirm/',
    body,
    callback: (response) => response.data,
  })
}

export const likedVideos = async (token, params) => {
  return await getQuery({
    path: '/api/users/me/',
    token,
    params,
    callback: (response) => response.data,
  })
}

export const getUsers = async (token, params) => {
  params = Object.entries(params).reduce(
    (a, [k, v]) => (v ? ((a[k] = v), a) : a),
    {}
  )
  return await getQuery({
    path: '/api/export-data/',
    token,
    params,
    callback: (response) => response.data,
  })
}

export const getRemainingVotes = async (token, params) => {
  params = Object.entries(params).reduce(
    (a, [k, v]) => (v ? ((a[k] = v), a) : a),
    {}
  )

  return await getQuery({
    path: '/api/users/remaining-votes/',
    token,
    params,
    // callback: (data) => data.remaining_votes,
    callback: (response) => response.data.remaining_votes,
  })
}

// export const getUserProfile = async () => {}

// export const updateUserProfile = async () => {}
