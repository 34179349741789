import { Button, Container } from 'react-bootstrap'
import YoutubeEmbed from './YoutubeEmbed'
import { DownloadModalContext } from '../pages/Home'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { AppContext } from '../context'
import infoBackground from '../assets/landing/AddVideoBackground.png'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const HowToEnter = ({ openVideo, className }) => {
  const { setShow } = useContext(DownloadModalContext)
  const { token, timelineCompetitions } = useContext(AppContext)

  const currentVideoURL = timelineCompetitions.currentCompetition?.explicative_video
  const lastCompetitionVideoURL = timelineCompetitions.lastCompetitions[0]?.explicative_video
  const nextCompetitionVideoURL = timelineCompetitions.nextCompetition?.explicative_video

  const getIDFromURL = (url) => {
    return url ? url.split('/').pop() : null
  }

  const currentID = getIDFromURL(currentVideoURL)
  const lastCompetitionID = getIDFromURL(lastCompetitionVideoURL)
  const nextCompetitionID = getIDFromURL(nextCompetitionVideoURL)

  return (
    <section className={classNames('HowToEnter mb-0 mb-xl-5', className)} style={{maxWidth: 'max-content'}} id="how-to-enter">
      <h1 className="text-Landing fw-600 text-center mb-5">How to enter</h1>
      {timelineCompetitions.currentCompetition && !timelineCompetitions.nextCompetition && (
        <YoutubeEmbed video={`https://www.youtube.com/embed/${currentID}`} className="HowToEnter__Video" />
      )}
      {timelineCompetitions.lastCompetitions && timelineCompetitions.lastCompetitions.length !== 0 && !timelineCompetitions.nextCompetition && !timelineCompetitions.currentCompetition && (
        <YoutubeEmbed video={`https://www.youtube.com/embed/${lastCompetitionID}`} className="HowToEnter__Video" />
      )}
      {timelineCompetitions.nextCompetition && (
        <YoutubeEmbed video={`https://www.youtube.com/embed/${nextCompetitionID}`} className="HowToEnter__Video" />
      )}
      <div className="HowToEnter__Info">
        <div className="d-flex gap-4">
          <div className="d-flex flex-column gap-2">
            <p className="caption-2">1. Download</p>
            <p className="mb-0 fw-400 ffam-FreeSans" style={{fontSize: '18px'}} >
              Your video must include the Outback Explorer Competition video frame.
              <br />
              Place the frame at both the beginning and the end of your video.
            </p>
            <Button
              id="Step1-Download-Btn-Btn"
              variant={'rs'}
              className="HowToEnter__Button px-0 px-md-5 mt-2"
              onClick={() => {
                setShow(true)
              }}
            >
              Download video frames
            </Button>
          </div>
        </div>
        <div className="d-flex gap-4">
          <div className="d-flex flex-column gap-1">
            <p className="caption-2">2. Create</p>
            <p className="mb-0 fw-400 ffam-FreeSans" style={{fontSize: '18px'}}>
              Post your video on Instagram, YouTube or TikTok.
            </p>

          </div>
        </div>
        <div className="d-flex gap-4">
          <div className="d-flex flex-column gap-1">
            <p className="caption-2">3. Upload</p>
            <p className="mb-0 fw-400 ffam-FreeSans" style={{fontSize: '18px'}}>
              Submit your video for entry by completing the form below.
            </p>
            <div className="HowToEnter__CreateBox position-relative my-3">
              <p className="fw-bold text-3 ffam-FreeSans">
                If posting from Instagram
              </p>
              <p className="mb-0 text-3 ffam-FreeSans">
                1. Set your account to <strong>public</strong>.
              </p>
              <p className="mb-0 text-3 ffam-FreeSans">
                2. Use <strong>original audio</strong> only.
              </p>
              <p className="text-3 ffam-FreeSans">
                3. Use <strong>portrait</strong> aspect ratio.
              </p>
              <p className="mb-0 fw-400 ffam-FreeSans text-3" >
                This ensures your video is viewable on the Outback Explorer Competition website.
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex gap-4">
          <div className="d-flex flex-column gap-1">
            <p className="caption-2">4. Share & vote</p>
            <p className="mb-0 fw-400 ffam-FreeSans" style={{fontSize: '18px'}}>
              Once uploaded, use the <strong>Share Video</strong> function <strong>to get more votes.</strong>
            </p>
            <p className="mb-0 fw-400 ffam-FreeSans" style={{fontSize: '18px'}}>
              You have 1 vote to use everyday.
            </p>
            <p className="mb-0 fw-400 ffam-FreeSans" style={{fontSize: '18px'}}>
              No account is needed to vote, but creating an account <strong>gives you 5 votes per day, plus the chance to win a Premium Adventurer Rescue Swag!</strong>
            </p>
            {!token ? (
              <Link to="/register">
                <Button
                  id="Step4-Create-Account-Btn"
                  variant={'rs'}
                  className="HowToEnter__Button px-5 mt-2"
                >
                  Create voting account
                </Button>
              </Link>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

HowToEnter.propTypes = {
  openVideo: PropTypes.func.isRequired,
  className: PropTypes.string,
}

export default HowToEnter
