import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { getRemainingVotes, userMe } from '../api/users'
import { getAdminSetting } from '../api/server'
import { useQuery } from 'react-query'
import { getCompetitions } from '../api/competitions'

export const AppContext = React.createContext()

const AppContextProvider = ({ children }) => {
  // State to avoid deleting token from local storage on page reload
  const [firstCharge, setFirstCharge] = useState(true)
  const [token, setToken] = useState(undefined)
  const [unregisteredUserId, setUnregisteredUserId] = useState(undefined)
  const [user, setUser] = useState(null)
  const [previousPath, setPreviousPath] = useState(null)
  const { data: remainingVotes, refetch: refreshRemainingVotes } = useQuery(
    ['getRemainingVotes', token, unregisteredUserId],
    () =>
      getRemainingVotes(token, {
        unregistered_user_id: !token ? unregisteredUserId : '',
      }),
    {
      initialData: 1,
    }
  )
  const [showVideoModal, setShowVideoModal] = useState(false)
  const [modalData, setModalData] = useState({
    already_voted: false,
    description: '',
    hosted_in: '',
    id: 0,
    is_staff_pick: false,
    name: '',
    new: false,
    recommended: false,
    thumbnail: '',
    user_age: '',
    user_city: '',
    user_country: '',
    user_email: '',
    user_last_name: '',
    user_name: '',
    user_phone: '',
    user_votes_today: 0,
    video_link: '',
    votes: 0,
    isAdmin: false,
    isExample: false,
    refetch: () => {},
  })
  const [userLog, setUserLog] = useState({
    email: '',
    first_name: '',
    last_name: '',
  })
  const [serverInfo, setServerInfo] = useState({
    id: 1,
    is_automated_emails_enabled: true,
    is_upload_videos_enabled: true,
  })

  const currentDate = new Date()

  const { data: competitionsData } = useQuery(
    ['getCompetitions'],
    () => getCompetitions(),
    {
      initialData: {
        data: {
          competitions: [],
          current_page: 1,
          total_pages: 1,
        },
        ok: false,
      },
      refetchOnWindowFocus: false
    }
  )
  const timelineCompetitions = useMemo(() => {
    const competitions = {
      nextCompetition: null,
      currentCompetition: null,
      lastCompetitions: null,
    }
  
    if (competitionsData?.data?.competitions) {
      const ongoingCompetitions = competitionsData.data.competitions.filter(
        (competition) => {
          const competitionStartDate = new Date(competition.start_date)
          const competitionEndDate = new Date(competition.end_date)
          return competitionStartDate <= currentDate && competitionEndDate >= currentDate
        }
      )
  
      if (ongoingCompetitions.length !== 0) {
        competitions.currentCompetition = ongoingCompetitions[0]
      }
  
      const futureCompetitions = competitionsData.data.competitions.filter(
        (competition) => {
          const competitionStartDate = new Date(competition.start_date)
  
          const threeWeeksFromNow = new Date(currentDate)
          threeWeeksFromNow.setDate(currentDate.getDate() + 21)
          
          return competitionStartDate > currentDate && competitionStartDate <= threeWeeksFromNow
        }
      )
  
      if (futureCompetitions.length !== 0) {
        competitions.nextCompetition = futureCompetitions[0]
      }
  
      const pastCompetitions = competitionsData.data.competitions.filter(
        (competition) => {
          const competitionEndDate = new Date(competition.end_date)
          return competitionEndDate < currentDate
        }
      )
  
      if (pastCompetitions.length !== 0) {
        pastCompetitions.sort((a, b) =>
          new Date(b.end_date) - new Date(a.end_date)
        )
        competitions.lastCompetitions = pastCompetitions
      }
    }
  
    return competitions
  
  }, [competitionsData, currentDate])
  


  const competitionInfo = useMemo(() => {

    if (timelineCompetitions.nextCompetition) {
      return {
        state: 'nearFuture',
        competition: timelineCompetitions.nextCompetition,
      }
    }
    if (timelineCompetitions.currentCompetition) {
      return {
        state: 'ongoing',
        competition: timelineCompetitions.currentCompetition,
      }
    }

    if (timelineCompetitions.lastCompetitions) {
      return {
        state: 'finished',
        competition: timelineCompetitions.lastCompetitions[0],
      }
    }
    return null
   
  }, [timelineCompetitions])

  // Check token and get user data in first charge
  useEffect(() => {
    checkToken()
    checkUnregisteredUserId()

    setFirstCharge(false)
  }, [])

  // Save and remove token in local storage
  useEffect(() => {
    if (!firstCharge) {
      if (token) {
        localStorage.setItem('token', token)
        checkToken()
      } else {
        localStorage.removeItem('token')
      }
    }
  }, [token])

  useEffect(() => {
    if (!firstCharge) {
      if (unregisteredUserId) {
        localStorage.setItem('unregisteredUserId', unregisteredUserId)
      } else {
        localStorage.removeItem('unregisteredUserId')
      }
    }
  }, [unregisteredUserId])

  // Catch local storage changes
  useEffect(() => {
    function storageEventHandler(event) {
      if (!event.isTrusted) {
        if (event.currentTarget.localStorage.token && !token) {
          setToken(event.currentTarget.localStorage.token)
        } else if (token) {
          setToken('')
        }
      } else {
        if (event.key === 'token') {
          setToken(event.newValue)
        }
      }
    }

    window.addEventListener('storage', storageEventHandler)
  }, [])

  const checkToken = async () => {
    const savedToken = localStorage.getItem('token')

    if (savedToken) {
      setToken(savedToken)
      updateUser(savedToken)
    }
    updateServerInfo(savedToken)
  }

  const checkUnregisteredUserId = () => {
    const savedUnregisteredUserId = localStorage.getItem('unregisteredUserId')

    if (savedUnregisteredUserId) setUnregisteredUserId(savedUnregisteredUserId)
  }

  const updateUser = async (savedToken) => {
    const data = await userMe(savedToken || token)

    if (data.error) console.error(data.error)
    else if (data) {
      setUser(data)

      setUserLog({
        email: data.user_serializer.email,
        first_name: data.user_serializer.first_name,
        last_name: data.user_serializer.last_name,
      })
    }
  }

  const updateServerInfo = async (savedToken) => {
    const response = await getAdminSetting(savedToken || token)

    if (response.error) console.error(response.error)
    else if (response) setServerInfo(response)
  }

  const openVideoModal = (data) => {
    setModalData(data)
    setShowVideoModal(true)
  }

  const viewVideoDetail = (data) => {
    setModalData(data)
  }

  const closeVideoModal = () => {
    setShowVideoModal(false)
    setTimeout(
      () =>
        setModalData({
          already_voted: false,
          description: '',
          hosted_in: '',
          id: 0,
          is_staff_pick: false,
          name: '',
          new: false,
          recommended: false,
          thumbnail: '',
          user_age: '',
          user_city: '',
          user_country: '',
          user_email: '',
          user_last_name: '',
          user_name: '',
          user_phone: '',
          user_votes_today: 0,
          video_link: '',
          votes: 0,
          admin: false,
          refetch: () => {},
        }),
      300
    )
  }

  const values = {
    firstCharge,
    token,
    setToken,
    unregisteredUserId,
    setUnregisteredUserId,
    user,
    updateUser,
    remainingVotes,
    refreshRemainingVotes,
    showVideoModal,
    modalData,
    openVideoModal,
    viewVideoDetail,
    closeVideoModal,
    userLog,
    setUserLog,
    serverInfo,
    setServerInfo,
    competitionInfo,
    timelineCompetitions,
    previousPath,
    setPreviousPath,
    updateServerInfo
  }

  return <AppContext.Provider value={values}>{children}</AppContext.Provider>
}

AppContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AppContextProvider
