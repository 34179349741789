import {Col, Modal, Row, Tab, Tabs} from 'react-bootstrap'
import {useEffect} from 'react'
import PropTypes from 'prop-types'
import {faX} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import TikTokDesktopInstructions from '../assets/landing/TikTokDesktopInstructions.png'
import TikTokMobileInstructions from '../assets/landing/TikTokMobileInstructions.png'
import InstagramDesktopInstructions from '../assets/landing/InstagramDesktopInstructions.png'
import InstagramMobileInstructions from '../assets/landing/InstagramMobileInstructions.png'
import YoutubeDesktopInstructions from '../assets/landing/YoutubeDesktopInstructions.png'
import YoutubeMobileInstructions from '../assets/landing/YoutubeMobileInstructions.png'


const ModalInfoHowToEnter = (props) => {

  const handleCloseModal = () => {
    props.close()
  }

  useEffect(() => {

  })

  return (
    <>
      <Modal
        show={props.show}
        onHide={handleCloseModal}
        size="lg"
        centered
      >
        <Modal.Body>
          <div className="float-end text-end cursor-pointer" onClick={() => handleCloseModal()}>
            <FontAwesomeIcon icon={faX} />
          </div>
          <Tabs
            fill
            variant="tabs_form"
            className="mb-4 AllVideos"
            defaultActiveKey={props.socialNet}
          >
            <Tab eventKey="TikTok" title="TikTok">
              <Row>
                <Col className="border-end">
                  <div className="desktop-mobile-button mb-4">
                    Desktop
                  </div>
                  <img src={TikTokDesktopInstructions} alt="Tiktok desktop" className="img-fluid" />
                </Col>
                <Col>
                  <div className="desktop-mobile-button mb-4">
                    Mobile
                  </div>
                  <img src={TikTokMobileInstructions} alt="Tiktok mobile" className="img-fluid" />
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="Instagram" title="Instagram">
              <Row>
                <Col className="border-end">
                  <div className="desktop-mobile-button mb-4">
                    Desktop
                  </div>
                  <img src={InstagramDesktopInstructions} alt="Instagram desktop" className="img-fluid" />
                </Col>
                <Col>
                  <div className="desktop-mobile-button mb-4">
                    Mobile
                  </div>
                  <img src={InstagramMobileInstructions} alt="Instagram mobile" className="img-fluid" />
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="Youtube" title="Youtube">
              <Row>
                <Col className="border-end">
                  <div className="desktop-mobile-button mb-4">
                    Desktop
                  </div>
                  <img src={YoutubeDesktopInstructions} alt="Youtube desktop" className="img-fluid" />
                </Col>
                <Col>
                  <div className="desktop-mobile-button mb-4">
                    Mobile
                  </div>
                  <img src={YoutubeMobileInstructions} alt="Youtube mobile" className="img-fluid" />
                </Col>
              </Row>
            </Tab>
          </Tabs>
          <Row>
            <Col></Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ModalInfoHowToEnter

ModalInfoHowToEnter.propTypes = {
  socialNet: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
}
