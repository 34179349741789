// import { useRef } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import PropTypes from 'prop-types'

const TextInputEditor = (props) => {
  const modules = {
    toolbar: {
      container: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ color: [] }, { background: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: [] }],
        ['link', 'image'],
        ['clean'],
      ],
    },
  }

  const formats = [
    'font',
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'color',
    'background',
    'list',
    'align',
    'link',
    'image',
  ]

  // const quillRef = useRef()
  
  return (
    <ReactQuill
      className="text-input-editor"
      theme='snow'
      value={props.value}
      onChange={props.setValue}
      // ref={quillRef}
      modules={modules}
      formats={formats}
    />
  )
}

export default TextInputEditor

TextInputEditor.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func
}