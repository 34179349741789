import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import useOnClickOutside from '../hooks/useOnClickOutside'

const CustomDropdown = ({ competitionsData, handleCompetitionSelect, competitionId, withAllCompetitions }) => {
  const [isOpen, setIsOpen] = useState(false)
  const selectedCompetition = competitionsData.data.competitions.find((comp) => comp.id === competitionId)
  const ref = useRef()

  useOnClickOutside(ref, () => {
    setTimeout(() => {
      setIsOpen(false)
    }, 100)
  })

  const toggleDropdown = () => setIsOpen(!isOpen)

  const handleSelect = (id) => {
    handleCompetitionSelect(id)
    setIsOpen(false)
  }

  return (
    <div className="custom-dropdown">
      <div className="custom-dropdown__header" onClick={toggleDropdown}>
        {selectedCompetition ? selectedCompetition.name : withAllCompetitions ? 'All competitions' : 'Select a competition'}
      </div>
      {isOpen && (
        <div className="custom-dropdown__list" ref={ref}>
          {withAllCompetitions && (
            <div className="custom-dropdown__item" onClick={() => handleSelect('')}>
              All competitions
            </div>
          )}
          {competitionsData.data.competitions.map((competition, index) => (
            <div key={competition.id} className="custom-dropdown__item" onClick={() => handleSelect(competition.id)}>
              {index === 0 ? 'Current competition' : competition.name}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default CustomDropdown

CustomDropdown.propTypes = {
  competitionsData: PropTypes.object.isRequired,
  handleCompetitionSelect: PropTypes.func.isRequired,
  competitionId: PropTypes.any.isRequired,
  withAllCompetitions: PropTypes.bool
}