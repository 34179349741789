import pickImage from '../assets/landing/addImage.png'

const PickImg = () => {
  return (
    <div className="PickImg">
      <img src={pickImage} alt=""/>
    </div>
  )
}

export default PickImg