import {  URL as API_URL } from '../constants'

export const getQueryNew = async ({
  path,
  token,
  params,
  httpHeaders = { 'Content-Type': 'application/json' },
  requestInit,
}) => {
  const urlPath = new URL(`api${path}`, API_URL)
  if (params) urlPath.search = new URLSearchParams(params).toString()

  const headers = new Headers(httpHeaders)
  if (token) {
    headers.append('Authorization', `Token ${token}`)
  }

  try {
    const response = await fetch(urlPath.toString(), {
      headers,
      ...requestInit,
    })

    if (response.status === 401) deleteInvalidToken()
    const data = await response.json()

    if (!response.ok)
      return { response, ok: response.ok, data: data  }
    else return { response, ok: response.ok, data: data }
  } catch (error) {
    const response = new Response('something went wrong', {
      status: 500,
      headers,
    })
    return {
      response,
      ok: false,
      data: `something went wrong, error ${JSON.stringify(error)}`,
    }
  }
}

export const postQueryNew = async ({
  path,
  token,
  body,
  httpHeaders = { 'Content-Type': 'application/json' },
  requestInit,
}) => {
  const urlPath = new URL(`api${path}`, API_URL)

  const headers = new Headers(httpHeaders)
  if (token) {
    headers.append('Authorization', `Token ${token}`)
  }

  try {
    const response = await fetch(urlPath.toString(), {
      method: 'POST',
      headers,
      body,
      ...requestInit,
    })

    if (response.status === 401) deleteInvalidToken()
    const data = await response.json()

    if (!response.ok)
      return { response, ok: response.ok, data: data }
    else return { response, ok: response.ok, data: data }
  } catch (error) {
    const response = new Response('something went wrong', {
      status: 500,
      headers,
    })
    return {
      response,
      ok: false,
      data: `something went wrong, error ${JSON.stringify(error)}`,
      body,
    }
  }
}

export const deleteQueryNew = async ({
  path,
  token,
  body,
  httpHeaders = { 'Content-Type': 'application/json' },
  requestInit,
}) => {
  const urlPath = new URL(`api${path}`, API_URL)

  const headers = new Headers(httpHeaders)
  if (token) {
    headers.append('Authorization', `Token ${token}`)
  }

  try {
    const response = await fetch(urlPath.toString(), {
      method: 'DELETE',
      headers,
      body,
      ...requestInit,
    })

    let data = null
    if (response.status === 401) deleteInvalidToken()
    if (response.status !== 204) data = await response.json()

    if (!response.ok)
      return { response, ok: response.ok, data: data }
    else return { response, ok: response.ok, data: data }
  } catch (error) {
    const response = new Response('something went wrong', {
      status: 500,
      headers,
    })
    return {
      response,
      ok: false,
      data: `something went wrong, error ${JSON.stringify(error)}`,
    }
  }
}

export const putQueryNew = async ({
  path,
  token,
  body,
  httpHeaders = { 'Content-Type': 'application/json' },
  requestInit,
}) => {
  const urlPath = new URL(`api${path}`, API_URL)

  const headers = new Headers(httpHeaders)
  if (token) {
    headers.append('Authorization', `Token ${token}`)
  }

  try {
    const response = await fetch(urlPath.toString(), {
      method: 'PUT',
      headers,
      body,
      ...requestInit,
    })

    if (response.status === 401) deleteInvalidToken()

    const data = await response.json()

    if (!response.ok)
      return { response, ok: response.ok, data: data }
    else return { response, ok: response.ok, data: data }
  } catch (error) {
    const response = new Response('something went wrong', {
      status: 500,
      headers,
    })
    return {
      response,
      ok: false,
      data: `something went wrong, error ${JSON.stringify(error)}`,
    }
  }
}

export const patchQueryNew = async ({
  path,
  token,
  body,
  httpHeaders = { 'Content-Type': 'application/json' },
  requestInit,
}) => {
  const urlPath = new URL(`api${path}`, API_URL)

  const headers = new Headers(httpHeaders)
  if (token) {
    headers.append('Authorization', `Token ${token}`)
  }

  try {
    const response = await fetch(urlPath.toString(), {
      method: 'PATCH',
      headers,
      body,
      ...requestInit,
    })

    if (response.status === 401) deleteInvalidToken()

    const data = await response.json()

    if (!response.ok)
      return { response, ok: response.ok, data: data }
    else return { response, ok: response.ok, data: data }
  } catch (error) {
    const response = new Response('something went wrong', {
      status: 500,
      headers,
    })
    return {
      response,
      ok: false,
      data: `something went wrong, error ${JSON.stringify(error)}`,
    }
  }
}

const deleteInvalidToken = () => {
  localStorage.removeItem('token')
  window.dispatchEvent(new Event('storage'))
}

// export const xmlPost = async ({
//   path,
//   token,
//   body,
//   httpHeaders = { 'Content-Type': 'application/json' },
// }: XmlQuery) => {
//   const urlPath = new URL(`api${path}`, API_URL)

//   try {
//     const xhr = new XMLHttpRequest()
//     xhr.open('POST', urlPath.toString())

//     if (token) xhr.setRequestHeader('Authorization', `Token ${token}`)

//     for (const property in httpHeaders) {
//       xhr.setRequestHeader(property, httpHeaders[property])
//     }

//     xhr.send(body)
//   } catch (error) {
//     console.log(error)
//   }
// }