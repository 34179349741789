import { useEffect, useState } from 'react'
import { useContext } from 'react'
import { AppContext } from '../../context'
import { getJudges, deleteJudge } from '../../api/judges'
import { useQuery } from 'react-query'
import { Spinner } from 'react-bootstrap'
import ModalJudges from '../../components/ModalJudges'
import JudgesCard from '../../components/JudgesCard'
import { patchAdminSetting } from '../../api/server'

const AdminJudges = () => {
  const { token, serverInfo, updateServerInfo } = useContext(AppContext)

  const [activeJudge, setActiveJudge] = useState(serverInfo?.is_judges_on ?? false)
  const [showModal, setShowModal] = useState({ show: false, judge: null })
  const [loadingId, setLoadingId] = useState(null)
  const [loadingAdminSetting, setLoadingAdminSetting] = useState(false)

  const {
    data: dataJudges,
    isLoading: loadingJudges,
    refetch: refetchJudges
  } = useQuery(['getJudges', token], () => getJudges(token), { refetchOnWindowFocus: false })

  useEffect(() => {
    setActiveJudge(serverInfo?.is_judges_on ?? false)
  }, [serverInfo])

  const toggleActiveJudge = () => {
    setActiveJudge(!activeJudge)
    handleActiveJudge()
  }

  const handleActiveJudge = async () => {
    setLoadingAdminSetting(true)
    try {
      await patchAdminSetting(token, { is_judges_on: !activeJudge })
      updateServerInfo(token)
    } catch (error) {
      setActiveJudge(!activeJudge)
    } finally {
      setTimeout(() => {
        setLoadingAdminSetting(false)
      }, 1000)
    }
  }

  if (loadingJudges) {
    return (
      <div className="text-center">
        <Spinner animation="border" variant="warning" />
      </div>
    )
  }

  const handleDelete = async (judgeId) => {
    setLoadingId(judgeId)
    try {
      await deleteJudge(token, judgeId)
    } finally {
      setLoadingId(null)
      refetchJudges()
    }
  }

  return (
    <div className="AdminJudges d-flex flex-column gap-4">
      <div className="d-flex align-items-center justify-content-between gap-4">
        <div
          className="d-flex align-items-center gap-2 cursor-pointer"
          onClick={() => {
            if (!loadingAdminSetting) {
              toggleActiveJudge()
            }
          }}
        >
          <Spinner animation="border" variant="gray" size='sm' style={{ display: loadingAdminSetting ? 'block' : 'none' }} />
          <p className="text_primary fw-normal text-4" style={{ marginBottom: '-2px' }}>
            Judges active
          </p>
          <button className={`switch ${activeJudge ? 'active' : ''}`} />
        </div>
        <button
          type="button"
          className="btn btn-rs-primary"
          onClick={() => {
            setShowModal({ show: true, judge: null })
          }}
        >
          Add new judge
        </button>
      </div>

      <h4 className="mt-4">Judges list</h4>
      <div className="d-flex flex-column gap-4 px-4">
        {dataJudges?.data?.map((judge) => (
          <JudgesCard
            key={judge.id}
            judge={judge}
            onEdit={() => setShowModal({ show: true, judge })}
            onDelete={() => handleDelete(judge.id)}
            loading={loadingId === judge.id}
          />
        ))}
      </div>

      <ModalJudges
        title={showModal.judge ? 'Edit judge' : 'Add judge'}
        show={showModal.show}
        onClose={() => setShowModal({ show: false, judge: null })}
        judge={showModal.judge}
        refetchJudges={refetchJudges}
      />
    </div>
  )
}

export default AdminJudges
