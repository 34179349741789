import { useEffect, useState } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { Link, useParams } from 'react-router-dom'
import { confirmEmail } from '../../api/users'

const RegisterToken = () => {
  const [menssage, setMenssage] = useState('')
  const { token } = useParams()

  useEffect(() => {
    const doCall = async () => {
      const response = await confirmEmail(token)

      if(response.error)
        setMenssage('Opss, Something went wrong.')
      else if(response.detail === 'Not found.')
        setMenssage('This account is already activated.')
      else if (response) {
        setMenssage('Congratulations, your account is activated!')
      }
    } 
    doCall()
  }, [token])

  return (
    <>
      <Helmet>
        <title>Account | Outback Explorer Competition</title>
      </Helmet>
      <div className="AuthContainer d-flex justify-content-center align-items-center">
        <div className="AuthBox__RegisterToken bg-white"  >
          <h1 className="text-center mb-4">Account</h1>
          {menssage ? (
            <p className="text-2 fw-semibold text-center">{menssage}</p>
          ) : (
            <Spinner animation="border" variant="warning" />
          )}
          <Link to="/login">
            <Button variant="rs px-5">
            Log in
            </Button>
          </Link>
        </div>
      </div>
    </>
  )
}

export default RegisterToken