import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

function TikTokEmbed({ videoSrc, className }) {
  const containerRef = useRef(null)

  useEffect(() => {
    let scriptElement = null

    const loadTikTokEmbed = () => {
      if (typeof window !== 'undefined' && containerRef.current) {
        containerRef.current.innerHTML = ''

        const blockquote = document.createElement('blockquote')
        blockquote.className = 'tiktok-embed'
        blockquote.cite = videoSrc
        blockquote.setAttribute('data-video-id', videoSrc.split('/').pop() || '')
        Object.assign(blockquote.style, {
          width: '100%',
          height: '100%',
          border: '0',
          margin: '0',
          background: '#FFF',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden'
        })

        const link = document.createElement('a')
        link.href = videoSrc
        blockquote.appendChild(link)

        containerRef.current.appendChild(blockquote)

        // overflow hidden in iframe
        const observer = new MutationObserver(() => {
          const iframe = blockquote.querySelector('iframe')
          if (iframe) {
            Object.assign(iframe.style, {
              overflow: 'hidden',
              width: '100%',
              height: '100%',
            })
          }
        })

        observer.observe(blockquote, { childList: true, subtree: true })

        if (!document.querySelector('script[src="https://www.tiktok.com/embed.js"]')) {
          scriptElement = document.createElement('script')
          scriptElement.src = 'https://www.tiktok.com/embed.js'
          scriptElement.async = true
          document.body.appendChild(scriptElement)
        } else {
          if (window.tiktok && typeof window.tiktok.reload === 'function') {
            window.tiktok.reload()
          } else {
            document.body.removeChild(document.querySelector('script[src="https://www.tiktok.com/embed.js"]'))
            scriptElement = document.createElement('script')
            scriptElement.src = 'https://www.tiktok.com/embed.js'
            scriptElement.async = true
            document.body.appendChild(scriptElement)
          }
        }
      }
    }

    loadTikTokEmbed()

    return () => {
      if (scriptElement) {
        document.body.removeChild(scriptElement)
      }
    }
  }, [videoSrc])

  return (
    <div ref={containerRef} className={`VideoDetailPage__VideoContainer--TikTok ${className}`}>
      {!videoSrc && <p>Invalid TikTok URL</p>}
    </div>
  )
}

TikTokEmbed.propTypes = {
  videoSrc: PropTypes.string.isRequired,
  className: PropTypes.string,
}

TikTokEmbed.defaultProps = {
  className: '',
}

export default TikTokEmbed
