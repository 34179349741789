import { getQueryNew, patchQueryNew, postQueryNew } from './apiQueries'


export const getCompetitions = async (token) => {
  return await getQueryNew({
    path: '/competitions/',
    params: {per_page: '999'},
    token,
  })
}

export const postCompetition = async (body, token) => {
  return await postQueryNew({
    path: '/competitions/',
    body,
    httpHeaders: {},
    token
  })
}
export const patchCompetition = async (body, token, competition_id) => {
  return await patchQueryNew({
    path: `/competition/${competition_id}/`,
    body,
    httpHeaders: {},
    token
  })
}

export const getCompetitionsDetail = async (id, token) => {
  return await getQueryNew({
    path: `/competition/${id}/`,
    token
  })
}