import {Row, Spinner} from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import {useContext, useMemo} from 'react'
import {useInfiniteQuery} from 'react-query'
import {getVideos, patchEditVideo} from '../../api/videos'
import { AppContext } from '../../context'
import AdminStaffPickVideoCard from '../../components/AdminStaffPickVideoCard'

const AdminVideos = () => {
  const { token } = useContext(AppContext)
  const { data, isFetching, hasNextPage, fetchNextPage, refetch } =
    useInfiniteQuery(
      ['staff_pick_videos', token],
      ({ pageParam = 1 }) =>
        getVideos({ 
          page: pageParam, 
          is_staff_pick: 'true',
          all: 'true',
          per_page: 12, 
        }, token),
      {
        getNextPageParam: (lastPage) =>
          lastPage.actual_page < lastPage.total_pages
            ? Number(lastPage.actual_page) + 1
            : false,
        initialData: { pages: [{ data: [] }], pageParams: 1 },
        enabled: token !== '',
      }
    )
  const videos = useMemo(
    () =>
      data.pages.reduce((prevVideos, page) => prevVideos.concat(page.data), []),
    [data]
  )

  const removeStaffPick = (videoid, is_staff_pick) => {
    const body = {
      is_staff_pick: is_staff_pick,
    }
    patchEditVideo(token, videoid , body)
      .then(() => {
        refetch()
      })
  }

  return (
    <>
      {token === '' || isFetching ? (
        <div className="text-center" style={{ marginBottom: '49.25px' }}>
          <Spinner animation="border" variant="warning" />
        </div>
      ) : videos.length === 0 ? (
        <div>No videos in staff pick.</div>
      ) : (
        <Row className="mb-4">
          {videos.length !== 0 ? videos.map((video) =>
            <AdminStaffPickVideoCard
              video={video}
              key={video.id}
              removeStaffPick={removeStaffPick}
              id={video.id}
              socialNet={video.hosted_in}
              likes={video.votes}
              name={video.name}
              videoName={video.name}
              videoSrc={video.video_link}
              firstName={video.user_name}
              lastName={video.user_last_name}
              description={video.description}
              staffPick={video.is_staff_pick}
              recommended={video.recommended}
              image={video.thumbnail}
              email={video.user_email}
              age={video.user_age}
              mobile={video.user_phone}
              city={video.user_city}
              country={video.user_country}
              voted={video.already_voted}
            />
          ): <></>}
        </Row>
      )}
      {hasNextPage? 
        <div className="text-center mb-5" onClick={() => fetchNextPage()}>
          <Button variant="rs-third">Load more videos</Button>
        </div>
        : null
      } 
    </>
  )
}

export default AdminVideos