import PropTypes from 'prop-types'
import { useQuery } from 'react-query'
import { getJudges } from '../api/judges'
import { useContext } from 'react'
import { AppContext } from '../context'
import { URL } from '../constants'
import { useMediaQuery } from '@uidotdev/usehooks'
import { IS_PRODUCTION } from '../constants'

const MeetOurJudges = () => {
  const { token } = useContext(AppContext)
  const is992 = useMediaQuery('(max-width: 992px)')

  const {
    data: dataJudges
  } = useQuery(['getJudges', token], () => getJudges(token), { refetchOnWindowFocus: false })

  const judges = dataJudges?.data?.map((judge, index) => (
    <div className={`MeetOurJudges__Card ${index % 2 !== 0 ? 'grid-inverse' : ''}`} key={index}>
      {(index % 2 === 0 || is992) && (
        <div className="MeetOurJudges__Card-img my-auto">
          <img src={IS_PRODUCTION === 'false' ? `${URL}/${judge.image}` : judge.image} alt={judge.name} />
        </div>
      )}
      <div className="d-flex flex-column gap-2 my-auto">
        <span className="MeetOurJudges__Card-name">{judge.name}</span>
        <div className="MeetOurJudges__Card-description" dangerouslySetInnerHTML={{ __html: judge.description }} />
      </div>
      {index % 2 !== 0 && !is992 && (
        <div className="MeetOurJudges__Card-img my-auto">
          <img src={IS_PRODUCTION === 'false' ? `${URL}/${judge.image}` : judge.image} alt={judge.name} />
        </div>
      )}
    </div>
  ))

  return (
    <div className="MeetOurJudges" id="meet-judges">
      <div>
        <p className="mb-5 text-Landing fw-600 text-center">Meet our judges</p>
      </div>
      <div className="MeetOurJudges__Container">{judges}</div>
    </div>
  )
}

export default MeetOurJudges
