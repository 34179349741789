import { Container, Modal, Spinner, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import AdminVideoRanking from '../../components/AdminVideoRanking'
import { useContext, useState, useEffect } from 'react'
import { AppContext } from '../../context'
import { useQuery } from 'react-query'
import { approveVideo, getLeaderboard, patchEditVideo } from '../../api/videos'
import { faCircle, faDotCircle } from '@fortawesome/free-regular-svg-icons'
import Button from 'react-bootstrap/Button'
import AdminAcceptedVideoCard from '../../components/AdminAcceptedVideoCard'
import { getVideos } from '../../api/videos'
import { getCompetitionsDetail } from '../../api/competitions'
import { REJECT_OPTIONS } from '../../constants'
import { getCompetitions } from '../../api/competitions'
import CustomDropdown from '../../components/CustomDropdown'

const AdminRanking = () => {
  const { token, user, timelineCompetitions } = useContext(AppContext)
  const [selectedVideo, setSelectedVideo] = useState(null)
  const [show, setShow] = useState(false)
  const [competition_id, setCompetitionId] = useState('') 
  const [loadingCompetitions, setLoadingCompetitions] = useState(false)
  const [rejectSelected, setRejectSelected] = useState('Your video is more than 2 minutes long.')
  const handleClose = () => { setShow(false); setSelectedVideo(null); setRejectSelected('Your video is more than 2 minutes long.') }

  const { data: competitionsData, isFetching: fetchingCompetitions } = useQuery(
    ['getCompetitions', token],
    () => getCompetitions(token)
  )

  const { isFetching, data, refetch } = useQuery(
    ['getLeaderboard', competition_id, token],
    () => {
      if (competition_id) {
        return getLeaderboard({ per_page: 10, competition_id }, token)
      }
    },
    {
      initialData: [],
      enabled: token !== '' && (user?.user_serializer.is_superuser ?? false),
    }
  )

  const { data: rejectedData, refetch: rejectedRefetch } = useQuery(
    ['getVideos', token],
    () => getVideos({ is_banned_from_leaderboard: 'true', all: 'true' }, token),
    {
      initialData: { data: [] },
    }
  )

  useEffect(() => {
    if (fetchingCompetitions) return
    if (competitionsData?.data?.competitions?.length > 0) {
      setCompetitionId(competitionsData.data.competitions[0].id)
    }
    setLoadingCompetitions(false)
  }, [competitionsData, fetchingCompetitions])
  

  const staffPickVideo = (videoid, is_staff_pick) => {
    const body = {
      is_staff_pick: is_staff_pick,
    }
    patchEditVideo(token, videoid, body).then(() => {
      refetch()
      rejectedRefetch()
    })
  }

  const { data: competitionsDetail } = useQuery(
    ['getCompetitions', competition_id, token],
    () => getCompetitionsDetail(competition_id, token),
    {
      initialData: {
        data: {
          name: '',
        },
        ok: false,
      },
    }
  )

  const bannedLeaderboardVideo = (videoid, is_banned_from_leaderboard) => {
    const body = {
      is_banned_from_leaderboard: is_banned_from_leaderboard,
    }
    patchEditVideo(token, videoid, body).then(() => {
      refetch()
      rejectedRefetch()
    })
  }

  const deleteFromRanking = (videoid) => {
    const body = {
      is_banned_from_leaderboard: true,
    }
    patchEditVideo(token, videoid, body).then(() => {
      refetch()
      rejectedRefetch()
    })
  }

  const rejectVideoModal = (videoid) => {
    setShow(true)
    setSelectedVideo(videoid)
  }

  const rejectVideo = () => {
    const body = {
      status: 'Rejected',
      reason: rejectSelected,
    }
    approveVideo(token, selectedVideo, body).then(() => {
      refetch()
      rejectedRefetch()
      setShow(false)
    })
  }

  const handleCompetitionSelect = (id) => {
    if(!id) return setCompetitionId(competitionsData.data.competitions[0].id)
    setCompetitionId(id)
  }

  return (
    <Container fluid>
      {competitionsData?.data?.competitions?.length > 0 && (
        <CustomDropdown
          competitionsData={competitionsData}
          handleCompetitionSelect={handleCompetitionSelect}
          competitionId={competition_id}
        />
      )}
      {competitionsDetail.ok && (
        <div>
          <h4 className='my-5'>Competition: {competitionsDetail.data.name}</h4>
        </div>
      )}
      {data.length !== 0 ? (
        data.map((element, index) => (
          <AdminVideoRanking
            video={element}
            key={element.id}
            id={element.id}
            socialNet={element.hosted_in}
            likes={element.votes}
            videoName={element.name}
            videoSrc={element.video_link}
            firstName={element.user_name}
            lastName={element.user_last_name}
            description={element.description}
            staffPick={element.is_staff_pick}
            email={element.user_email}
            city={element.user_city}
            country={element.user_country}
            mobile={element.user_phone}
            age={element.user_age}
            image={element.thumbnail}
            position={index + 1}
            deleteFromRanking={deleteFromRanking}
            staffPickVideo={staffPickVideo}
            rejectVideoModal={rejectVideoModal}
          />
        ))
      ) : isFetching ? (
        <div className="text-center">
          <Spinner animation="border" variant="warning" />
        </div>
      ) : (
        <></>
      )}
      {rejectedData.data.length !== 0 ? (
        <>
          <hr style={{ marginTop: 40 }} />
          <h5 style={{ marginBottom: 20 }}>Rejected videos from Leaderboard</h5>
        </>
      ) : null}
      <Row className="mb-4">
        {rejectedData.data.length !== 0 ? (
          rejectedData.data.map((video) => (
            <AdminAcceptedVideoCard
              key={video.id}
              staffPickVideo={staffPickVideo}
              rejectVideoModal={rejectVideoModal}
              id={video.id}
              socialNet={video.hosted_in}
              likes={video.votes}
              name={video.name}
              videoName={video.name}
              videoSrc={video.video_link}
              firstName={video.user_name}
              lastName={video.user_last_name}
              description={video.description}
              staffPick={video.is_staff_pick}
              image={video.thumbnail}
              email={video.user_email}
              age={video.user_age}
              mobile={video.user_phone}
              city={video.user_city}
              country={video.user_country}
              voted={video.already_voted}
              bannedLeaderboardVideo={bannedLeaderboardVideo}
              bannedLeaderboard={video.is_banned_from_leaderboard}
            />
          ))
        ) : (
          <></>
        )}
      </Row>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Select a reason</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="h-100 d-flex flex-column p-4">
            <div className="d-flex flex-column gap-3 mt-2">
              {REJECT_OPTIONS.map((option, index) => (
                <div
                  key={index}
                  className="d-flex cursor-pointer"
                  onClick={() => setRejectSelected(option)}
                >
                  {rejectSelected === option ? (
                    <FontAwesomeIcon
                      icon={faDotCircle}
                      size="lg"
                      className="me-2"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faCircle}
                      size="lg"
                      className="me-2"
                    />
                  )}
                  {option}
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={() => rejectVideo()}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  )
}

export default AdminRanking
