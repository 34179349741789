import { useState, useContext, useEffect, useMemo } from 'react'
import { Modal } from 'react-bootstrap'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'
import InputFile from './InputFile'
import { handleInputFile, getImageString } from '../utils/InputFile'
import PickImg from './PickImg'
import TextInputEditor from './TextInputEditor'
import { AppContext } from '../context'
import { addJudge, modifyJudge } from '../api/judges'
import { URL } from '../constants'
import { Spinner } from 'react-bootstrap'

const ModalJudges = (props) => {
  const { title, show, onClose, judge, refetchJudges } = props
  const { token } = useContext(AppContext)

  const [mediaURL, setMediaURL] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      image: null
    },
    validateOnChange: false,
    validate: (values) => {
      const errors = {}

      if (!values.name) {
        errors.name = 'Name is required'
      }

      if (!values.description) {
        errors.description = 'Description is required'
      }

      if (!values.image && values.image !== judge?.image) {
        errors.image = 'Image is required'
      }

      return errors
    },
    onSubmit: async (values) => {
      setLoading(true)
      setError('')
      formik.setErrors({})
      const formData = new FormData()
      formData.append('name', values.name)
      formData.append('description', values.description)
      if(!judge || values.image !== judge.image) {
        formData.append('image', values.image)
      }

      try {
        let response
        if (judge) {
          response = await modifyJudge(token, judge.id, formData)
        } else {
          response = await addJudge(token, formData)
        }

        if (response.status !== 201 && response.status !== 200) {
          return setError('Error saving judge')
        }

        onClose()
        formik.resetForm()
      } catch (error) {
        setError('Error saving judge')
      } finally {
        refetchJudges()
      }
      setLoading(false)
    }
  })

  useEffect(() => {
    if (judge) {
      formik.setValues({
        name: judge.name,
        description: judge.description,
        image: judge.image
      })
      setMediaURL(`${URL}${judge.image}`)
    }
  }, [judge])

  const clearMedia = (e) => {
    e.preventDefault()
    setMediaURL('')
    formik.setFieldValue('image', null)
  }

  useEffect(() => {
    return () => {
      formik.resetForm()
      setMediaURL('')
    }
  }, [show])

  const isFormUnchanged = useMemo(() => {
    if (!judge) return false
    return (
      formik.values.name === judge.name &&
      formik.values.description === judge.description &&
      formik.values.image === judge.image
    )
  }, [formik.values, judge])

  return (
    <Modal show={show} onHide={onClose} size="lg" centered style={{ overflow: 'hidden' }}>
      <Modal.Body>
        <div className="d-flex w-100 align-items-center justify-content-between">
          <Modal.Title className="text-center w-100">{title}</Modal.Title>
          <button type="button" className="btn-close" onClick={onClose} />
        </div>
        <div className="d-flex flex-column gap-4">
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-3 d-flex flex-column gap-2">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              {formik.errors.name && (
                <div className="fw-semibold text-5 text-red Input__Error" style={{ marginLeft: '4px' }}>
                  {formik.errors.name}
                </div>
              )}
            </Form.Group>
            <Form.Group className="mb-3 d-flex flex-column gap-2">
              <Form.Label>Description</Form.Label>
              <TextInputEditor
                value={formik.values.description}
                setValue={(newValue) => {
                  formik.setFieldValue('description', newValue)
                }}
              />
              {formik.errors.description && (
                <div className="fw-semibold text-5 text-red Input__Error" style={{ marginLeft: '4px' }}>
                  {formik.errors.description}
                </div>
              )}
            </Form.Group>
            <Form.Group className="mb-3 d-flex flex-column gap-2">
              <Form.Label>Image</Form.Label>
              <InputFile
                label="Upload image"
                name="image"
                className="InputFile_Container"
                accept={['image/apng', 'image/png', 'image/jpg', 'image/jpeg', 'image/webp', 'image/gif']}
                onChange={(event) =>
                  handleInputFile({
                    event,
                    fileHandler: (file) => {
                      getImageString(file, (data, imageString) => {
                        formik.setFieldValue('image', data)
                        setMediaURL(imageString)
                      })
                    },
                    fileTypes: ['image/apng', 'image/png', 'image/jpg', 'image/jpeg', 'image/webp', 'image/gif'],
                    fileMaxSize: 50
                  })
                }
              >
                {mediaURL ? (
                  <div className="d-flex align-items-center w-100 justify-content-center">
                    <div style={{ width: '100%', maxWidth: '200px', position: 'relative' }}>
                      <img src={mediaURL} alt="Background competition image" className="VideoContainer" />
                      <button
                        type="button"
                        className="btn-close CloseBtn"
                        aria-label="Close"
                        onClick={(e) => clearMedia(e)}
                      ></button>
                    </div>
                  </div>
                ) : (
                  <div className="EmptyVideoContainer" style={{ cursor: 'pointer' }}>
                    <div className="EmptyImage">
                      <PickImg />
                    </div>
                    <div className="TextVideoContainer">
                      <p className="mb-1 fw-bold">Add Image</p>
                      <p className="mb-1 fw-light">Max. file size 50 MB</p>
                    </div>
                  </div>
                )}
              </InputFile>
              {formik.errors.image && (
                <div className="fw-semibold text-5 text-red Input__Error" style={{ marginLeft: '4px' }}>
                  {formik.errors.image}
                </div>
              )}
            </Form.Group>
            {error && <div className="fw-semibold text-5 text-red text-center mt-2">{error}</div>}
            <div className="d-flex justify-content-end">
              <button type="submit" className="btn btn-primary" onClick={formik.handleSubmit} disabled={loading || isFormUnchanged}>
                {loading && <Spinner animation="border" size="sm" className="me-2" />}
                Save
              </button>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ModalJudges

ModalJudges.propTypes = {
  title: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  judge: PropTypes.object,
  refetchJudges: PropTypes.func
}
