import { faX } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'
import YoutubeEmbed from './YoutubeEmbed'

const YouTubeVideoModal = ({isVisible, handleClose, videoLinkId}) => {
  return (
    <Modal
      show={isVisible}
      onHide={handleClose}
      centered
      dialogClassName={'YouTubeVideoModal__Container'}
      className="p-0"
    >
      <Modal.Body className={'ModalVideo__Container'}>
        <div className="YouTubeVideoModal ratio ratio-16x9">
          <YoutubeEmbed video={`https://www.youtube.com/embed/${videoLinkId}`} className="HowToEnter__Video" />
        </div>
      </Modal.Body>
      <div className="ModalVideo__Close" onClick={handleClose}>
        <FontAwesomeIcon icon={faX} size="xl" style={{ height: 20 }} />
      </div>
    </Modal>
  )
}

YouTubeVideoModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  videoLinkId: PropTypes.string.isRequired,
}

export default YouTubeVideoModal