import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import Home from '../pages/Home'
import AllVideos from '../pages/AllVideos'
import Login from '../pages/auth/Login'
import Register from '../pages/auth/Register'
import AdminVideos from '../pages/admin/AdminVideos'
import AdminRanking from '../pages/admin/AdminRanking'
import AdminUsers from '../pages/admin/AdminUsers'
import AdminWinners from '../pages/admin/AdminWinners'
import NotFound from '../pages/NotFound'

import Layout from '../layout/Layout'
import AuthLayout from '../layout/AuthLayout'
import AdminLayout from '../layout/AdminLayout'
import ForgotPassword from '../pages/auth/ForgotPassword'
import LikedVideos from '../pages/user/LikedVideos'
import TermsAndConditions from '../pages/TermsAndConditions'
import RegisterToken from '../pages/auth/RegisterToken'
import WhoWeAre from '../pages/WhoWeAre'
import UnsubcribeEmail from '../pages/auth/UnsubcribeEmail'
import PastWinners from '../pages/PastWinners'
import ChoiceWinners from '../components/ChoiceWinners'
import AdminNewCompetition from '../pages/admin/AdminNewCompetition'
import AdminEditCompetition from '../pages/admin/AdminEditCompetition'
import Faqs from '../pages/Faqs'
import Inspiration from '../pages/Inspiration'
import VideoDetailPage from '../pages/VideoDetailPage'
import AdminJudges from '../pages/admin/AdminJudges'

const App = () => {
  return (
    <BrowserRouter>
      <Helmet>
        <link rel="preconnect" href="https://dev.visualwebsiteoptimizer.com" />
        <script type="text/javascript" id="vwocode">
          {`
            (window._vwo_code || (function() {
              var account_id = 738066,
                version = 2.1,
                settings_tolerance = 2000,
                hide_element = 'body',
                hide_element_style = 'opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important;transition:none !important;',
                f = false,
                w = window,
                d = document,
                v = d.querySelector('#vwocode'),
                ck = '_vwo_' + account_id + '_settings',
                cc = {};
              try {
                var c = JSON.parse(localStorage.getItem('_vwo_' + account_id + '_config'));
                cc = c && typeof c === 'object' ? c : {};
              } catch (e) {}
              var stt = cc.stt === 'session' ? w.sessionStorage : w.localStorage;
              var code = {
                nonce: v && v.nonce,
                use_existing_jquery: function() {
                  return typeof use_existing_jquery !== 'undefined' ? use_existing_jquery : undefined;
                },
                library_tolerance: function() {
                  return typeof library_tolerance !== 'undefined' ? library_tolerance : undefined;
                },
                settings_tolerance: function() {
                  return cc.st || settings_tolerance;
                },
                hide_element_style: function() {
                  return '{' + (cc.hes || hide_element_style) + '}';
                },
                hide_element: function() {
                  if (performance.getEntriesByName('first-contentful-paint')[0]) {
                    return '';
                  }
                  return typeof cc.he === 'string' ? cc.he : hide_element;
                },
                getVersion: function() {
                  return version;
                },
                finish: function(e) {
                  if (!f) {
                    f = true;
                    var t = d.getElementById('_vis_opt_path_hides');
                    if (t) t.parentNode.removeChild(t);
                    if (e) (new Image).src = 'https://dev.visualwebsiteoptimizer.com/ee.gif?a=' + account_id + e;
                  }
                },
                finished: function() {
                  return f;
                },
                addScript: function(e) {
                  var t = d.createElement('script');
                  t.type = 'text/javascript';
                  if (e.src) {
                    t.src = e.src;
                  } else {
                    t.text = e.text;
                  }
                  v && t.setAttribute('nonce', v.nonce);
                  d.getElementsByTagName('head')[0].appendChild(t);
                },
                load: function(e, t) {
                  var n = this.getSettings(),
                    i = d.createElement('script'),
                    r = this;
                  t = t || {};
                  if (n) {
                    i.textContent = n;
                    d.getElementsByTagName('head')[0].appendChild(i);
                    if (!w.vwo || vwo.cae) {
                      stt.removeItem(ck);
                      r.load(e);
                    }
                  } else {
                    var o = new XMLHttpRequest();
                    o.open('GET', e, true);
                    o.withCredentials = !t.dsc;
                    o.responseType = t.responseType || 'text';
                    o.onload = function() {
                      if (t.onloadCb) {
                        return t.onloadCb(o, e);
                      }
                      if (o.status === 200 || o.status === 304) {
                        _vwo_code.addScript({
                          text: o.responseText
                        });
                      } else {
                        _vwo_code.finish('&e=loading_failure:' + e);
                      }
                    };
                    o.onerror = function() {
                      if (t.onerrorCb) {
                        return t.onerrorCb(e);
                      }
                      _vwo_code.finish('&e=loading_failure:' + e);
                    };
                    o.send();
                  }
                },
                getSettings: function() {
                  try {
                    var e = stt.getItem(ck);
                    if (!e) {
                      return;
                    }
                    e = JSON.parse(e);
                    if (Date.now() > e.e) {
                      stt.removeItem(ck);
                      return;
                    }
                    return e.s;
                  } catch (e) {
                    return;
                  }
                },
                init: function() {
                  if (d.url.indexOf('__vwo_disable__') > -1) return;
                  var e = this.settings_tolerance();
                  w._vwo_settings_timer = setTimeout(function() {
                    _vwo_code.finish();
                    stt.removeItem(ck);
                  }, e);
                  var t;
                  if (this.hide_element() !== 'body') {
                    t = d.createElement('style');
                    var n = this.hide_element(),
                      i = n ? n + this.hide_element_style() : '',
                      r = d.getElementsByTagName('head')[0];
                    t.setAttribute('id', '_vis_opt_path_hides');
                    v && t.setAttribute('nonce', v.nonce);
                    t.setAttribute('type', 'text/css');
                    if (t.stylesheet) t.stylesheet.cssText = i;
                    else t.appendChild(d.createTextNode(i));
                    r.appendChild(t);
                  } else {
                    t = d.getElementsByTagName('head')[0];
                    var i = d.createElement('div');
                    i.style.cssText = 'z-index: 2147483647 !important;position: fixed !important;left: 0 !important;top: 0 !important;width: 100% !important;height: 100% !important;background: white !important;';
                    i.setAttribute('id', '_vis_opt_path_hides');
                    i.classList.add('_vis_hide_layer');
                    t.parentNode.insertBefore(i, t.nextSibling);
                  }
                  var o = window._vis_opt_url || d.url,
                    s = 'https://dev.visualwebsiteoptimizer.com/j.php?a=' + account_id + '&u=' + encodeURIComponent(o) + '&vn=' + version;
                  if (w.location.search.indexOf('_vwo_xhr') !== -1) {
                    this.addScript({
                      src: s
                    });
                  } else {
                    this.load(s + '&x=true');
                  }
                }
              };
              w._vwo_code = code;
              code.init();
            })());
          `}
        </script>
      </Helmet>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />

          <Route path="/all-videos/" element={<AllVideos />} />
          <Route path="/liked-videos" element={<LikedVideos />} />
          <Route path="/winners/:competition_id" element={<PastWinners />} />

          <Route path="/who-we-are" element={<WhoWeAre />} />
          <Route path="/terms&conditions" element={<TermsAndConditions />} />

          <Route path="/faqs" element={<Faqs />} />
          <Route path="/inspiration" element={<Inspiration />} />

          <Route path="/:year/:slug" element={<VideoDetailPage />} />

          <Route path="*" element={<NotFound />} />
        </Route>

        <Route path="/unsubscribe-email/:email" element={<UnsubcribeEmail />} />

        <Route element={<AuthLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/register/check-mail/:token" element={<RegisterToken />} />
        </Route>

        <Route element={<AdminLayout />}>
          <Route path="/admin-videos" element={<AdminVideos />} />
          <Route path="/admin-judges" element={<AdminJudges />} />
          <Route path="/admin-ranking" element={<AdminRanking />} />
          <Route path="/admin-users" element={<AdminUsers />} />

          <Route path="/admin-winners/:competition_id" element={<AdminWinners />} />
          <Route path="/admin-winners/edit/:competition_id" element={<AdminEditCompetition />} />

          <Route path="/new-competition" element={<AdminNewCompetition />} />
          <Route path="/people-choice-winners/:competition_id" element={<ChoiceWinners />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App